import {
  articleListPageTracking,
  articlePageTracking,
  event,
  featurePageTracking,
  landingPageTracking,
  productPageTracking,
} from ".";

export const trackTvlkImageClick = (page?: HeaderTrackingPage) => {
  switch (page) {
    case "landing-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: landingPageTracking.TRAVELOKA_FOR_CORPORATE_BUTTON,
      };
    case "feature-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: featurePageTracking.TRAVELOKA_FOR_CORPORATE_BUTTON,
      };
    case "product-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: productPageTracking.TRAVELOKA_FOR_CORPORATE_BUTTON,
      };
    case "article-list-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articleListPageTracking.TRAVELOKA_FOR_CORPORATE_BUTTON,
      };
    case "article-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articlePageTracking.TRAVELOKA_FOR_CORPORATE_BUTTON,
      };
    default:
      return undefined;
  }
};

export type HeaderTrackingPage =
  | "landing-page"
  | "feature-page"
  | "product-page"
  | "article-list-page"
  | "article-page";
export const trackFeatureButtonClick = (page?: HeaderTrackingPage) => {
  switch (page) {
    case "landing-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: landingPageTracking.FEATURE_BUTTON,
      };
    case "feature-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: featurePageTracking.FEATURE_BUTTON,
      };
    case "product-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: productPageTracking.FEATURE_BUTTON,
      };
    case "article-list-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articleListPageTracking.FEATURE_BUTTON,
      };
    case "article-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articlePageTracking.FEATURE_BUTTON,
      };
    default:
      return undefined;
  }
};

export const trackProductButtonClick = (page?: HeaderTrackingPage) => {
  switch (page) {
    case "landing-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: landingPageTracking.PRODUCT_BUTTON,
      };
    case "feature-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: featurePageTracking.PRODUCT_BUTTON,
      };
    case "product-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: productPageTracking.PRODUCT_BUTTON,
      };
    case "article-list-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articleListPageTracking.PRODUCT_BUTTON,
      };
    case "article-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articlePageTracking.PRODUCT_BUTTON,
      };
    default:
      return undefined;
  }
};

export const trackArticleButtonClick = (page?: HeaderTrackingPage) => {
  switch (page) {
    case "landing-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: landingPageTracking.ARTICLE_BUTTON,
      };
    case "feature-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: featurePageTracking.ARTICLE_BUTTON,
      };
    case "product-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: productPageTracking.ARTICLE_BUTTON,
      };
    case "article-list-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articleListPageTracking.ARTICLE_BUTTON,
      };
    case "article-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articlePageTracking.ARTICLE_BUTTON,
      };
    default:
      return undefined;
  }
};

export const trackLoginButtonClick = (page?: HeaderTrackingPage) => {
  switch (page) {
    case "landing-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: landingPageTracking.LOG_IN_BUTTON,
      };
    case "feature-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: featurePageTracking.LOG_IN_BUTTON,
      };
    case "product-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: productPageTracking.LOG_IN_BUTTON,
      };
    case "article-list-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articleListPageTracking.LOG_IN_BUTTON,
      };
    case "article-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articlePageTracking.LOG_IN_BUTTON,
      };
    default:
      return undefined;
  }
};

export const trackSignUpButtonClick = (page?: HeaderTrackingPage) => {
  switch (page) {
    case "landing-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: landingPageTracking.SIGN_UP_BUTTON,
      };
    case "feature-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: featurePageTracking.SIGN_UP_BUTTON,
      };
    case "product-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: productPageTracking.SIGN_UP_BUTTON,
      };
    case "article-list-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articleListPageTracking.SIGN_UP_BUTTON,
      };
    case "article-page":
      return {
        event: event.CORPORATE_TRAVEL,
        data: articlePageTracking.SIGN_UP_BUTTON,
      };
    default:
      return undefined;
  }
};

export const trackImInterestedButtonClick = (emailName: string) => ({
  event: event.CORPORATE_TRAVEL,
  data: landingPageTracking.IM_INTERESTED_BUTTON(emailName),
});

export const trackLogInHeroButtonClick = {
  event: event.CORPORATE_TRAVEL,
  data: landingPageTracking.LOG_IN_HERO_BUTTON,
};

export const trackSignUpHeroButtonClick = {
  event: event.CORPORATE_TRAVEL,
  data: landingPageTracking.SIGN_UP_HERO_BUTTON,
};

export const trackBenefitTabClick = (tab: string) => ({
  event: event.CORPORATE_TRAVEL,
  data: landingPageTracking.FEATURE_SELECT_BUTTON(tab),
});

export const trackServiceOfferSignUpButtonClick = {
  event: event.CORPORATE_TRAVEL,
  data: landingPageTracking.SERVICE_OFFER_SIGN_UP_BUTTON,
};

export const trackFeatureComparisonButtonClick = {
  event: event.CORPORATE_TRAVEL,
  data: landingPageTracking.FEATURE_COMPARISON_BUTTON,
};

export const trackReadArticleButtonClick = (
  articleTitle: string,
  articleDate: number,
  articleOrder: number
) => ({
  event: event.CORPORATE_TRAVEL,
  data: landingPageTracking.READ_ARTICLE(
    articleTitle,
    articleDate,
    articleOrder
  ),
});

export const trackBrowseMoreArticleButtonClick = {
  event: event.CORPORATE_TRAVEL,
  data: landingPageTracking.BROWSE_MORE_ARTICLE,
};

export const trackMerchantLendingButtonClick = {
  event: event.CORPORATE_TRAVEL,
  data: landingPageTracking.MERCHANT_LENDING_BUTTON,
};

export const trackArrangeAMeetingButtonClick = {
  event: event.CORPORATE_TRAVEL,
  data: landingPageTracking.ARRANGE_A_MEETING_BUTTON,
};

export const trackFeatureSignUpComparisonButtonClick = {
  event: event.CORPORATE_TRAVEL,
  data: featurePageTracking.SIGN_UP_COMPARISON_BUTTON,
};

export const trackFeatureLearnMoreButtonClick = {
  event: event.CORPORATE_TRAVEL,
  data: featurePageTracking.BIZCREDIT_BUTTON,
};

export const trackProductSignUpButtonClick = {
  event: event.CORPORATE_TRAVEL,
  data: productPageTracking.SIGN_UP_BUTTON,
};

export const trackArticleListReadMoreButtonClick = (
  articleTitle: string,
  articleDate: number,
  articleOrder: number
) => ({
  event: event.CORPORATE_TRAVEL,
  data: articleListPageTracking.ARTICLE(
    articleTitle,
    articleDate,
    articleOrder
  ),
});

export const trackArticleListPaginationButtonClick = (pageNumber: number) => ({
  event: event.CORPORATE_TRAVEL,
  data: articleListPageTracking.PAGE_NAVIGATION_BUTTON(pageNumber),
});

export const trackRelatedArticleReadMoreButtonClick = (
  articleTitle: string,
  articleDate: number,
  articleOrder: number
) => ({
  event: event.CORPORATE_TRAVEL,
  data: articlePageTracking.ARTICLE(articleTitle, articleDate, articleOrder),
});
