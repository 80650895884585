"use client";
import {
  Locale,
  useAuth,
  useChangeLocale,
  useContentResource,
  useLocale,
} from "@ctv/core";
import { ButtonSeo, TextSeo, Token, ViewSeo } from "@ctv/momentum";
import { useRef, useState } from "react";

import { appendTestId } from "@ctv/shared/utils/TestUtil";
import FlagEN from "@traveloka/icon-kit-web/react/IcFlagCircularEn";
import FlagID from "@traveloka/icon-kit-web/react/IcFlagCircularId";
import FlagTH from "@traveloka/icon-kit-web/react/IcFlagCircularTh";
import FlagVN from "@traveloka/icon-kit-web/react/IcFlagCircularVn";
import CheckMark from "@traveloka/icon-kit-web/react/IcSystemCheckmark";
import cssStyle from "./languageMenu.module.css";

import Dropdown, { DropdownHandler } from "../Dropdown/Dropdown";
import MenuItemSeo from "../MenuItem/MenuItemSeo";

type Props = {
  testID?: string;
};

export default function LanguageMenuSeo(props: Props) {
  const { testID } = props;
  const languageDropdownRef = useRef<DropdownHandler>(null!);

  const locale = useLocale();
  const { user } = useAuth();

  const [current, setCurrent] = useState<Locale>(locale);
  const changeLanguage = useChangeLocale();

  const content = useContentResource().CorporateLanguage;

  const menusEN = (locale: Locale) => ({
    text: content.englishText,
    name: locale,
    LeftIcon: <FlagEN width={24} height={24} />,
    LeftIconActive: <FlagEN width={24} height={24} />,
    onPress() {
      setCurrent(locale);
    },
  });

  const menusID = {
    text: content.indonesiaText,
    name: Locale.IDID,
    LeftIcon: <FlagID width={24} height={24} />,
    LeftIconActive: <FlagID width={24} height={24} />,
    onPress() {
      setCurrent(Locale.IDID);
    },
  };

  const menusTH = {
    text: content.thailandText,
    name: Locale.THTH,
    LeftIcon: <FlagTH width={24} height={24} />,
    LeftIconActive: <FlagTH width={24} height={24} />,
    onPress() {
      setCurrent(Locale.THTH);
    },
  };

  const menusVN = {
    text: content.vietnamText,
    name: Locale.VNVN,
    LeftIcon: <FlagVN width={24} height={24} />,
    LeftIconActive: <FlagVN width={24} height={24} />,
    onPress() {
      setCurrent(Locale.VNVN);
    },
  };

  function getMenus(country?: string) {
    switch (country) {
      case "TH":
        return [menusEN(Locale.ENTH), menusTH];
      case "VN":
        return [menusEN(Locale.ENVN), menusVN];
      case "ID":
        return [menusEN(Locale.ENID), menusID];
      default:
        // Enable if TH and VN copy resource is added
        // return [menusEN(Locale.ENID), menusID, menusTH, menusVN];
        return [menusEN(Locale.ENID), menusID];
    }
  }

  function selectLanguage() {
    changeLanguage(current);
    languageDropdownRef.current?.setIsCollapsed(true);
  }

  return (
    <Dropdown
      ref={languageDropdownRef}
      alignment="right"
      trigger={
        <ButtonSeo
          testID="header.language"
          variant="text-black"
          text={languageSwitcher(locale).text}
          startIcon={LanguageIcon(locale)}
          className={cssStyle.languageButton}
        />
      }
    >
      <ViewSeo className={cssStyle.dropdownLanguageMenu}>
        <TextSeo className={cssStyle.header}>{content.title}</TextSeo>
        {getMenus(user?.country).map((menu) => (
          <MenuItemSeo
            {...menu}
            testID={appendTestId(testID, `menu.language.${menu.name}`)}
            key={menu.name}
            className={cssStyle.menuItem}
            textClassName={cssStyle.menuText}
            activeClassName={cssStyle.activeMenuItem}
            isActive={menu.name === current}
            RightIcon={""}
            RightIconActive={<CheckMark color={Token.color.bluePrimary} />}
          />
        ))}
        <ViewSeo className={cssStyle.actions}>
          <ButtonSeo
            className={cssStyle.cancelButton}
            variant="secondary"
            onPress={() => {
              languageDropdownRef.current?.setIsCollapsed(true);
            }}
            text={content.cancelButtonText}
          />
          <ButtonSeo onPress={selectLanguage} text={content.selectButtonText} />
        </ViewSeo>
      </ViewSeo>
    </Dropdown>
  );
}

function LanguageIcon(locale: Locale) {
  switch (locale) {
    case Locale.IDID:
      return <FlagID color={Token.color.bluePrimary} width={24} height={24} />;
    case Locale.THTH:
      return <FlagTH color={Token.color.bluePrimary} width={24} height={24} />;
    case Locale.VNVN:
      return <FlagVN color={Token.color.bluePrimary} width={24} height={24} />;
    case Locale.ENID:
    case Locale.ENTH:
    case Locale.ENVN:
      return <FlagEN color={Token.color.bluePrimary} width={24} height={24} />;
  }
}

function languageSwitcher(locale: Locale) {
  switch (locale) {
    case Locale.IDID:
      return { text: "ID", icon: FlagID };
    case Locale.THTH:
      return { text: "TH", icon: FlagTH };
    case Locale.VNVN:
      return { text: "VN", icon: FlagVN };
    case Locale.ENID:
    case Locale.ENTH:
    case Locale.ENVN:
    default:
      return { text: "EN", icon: FlagEN };
  }
}
