"use client";
import { TouchableOpacity } from "react-native";

import { LocalizedLink, useAuth, useContentResource } from "@ctv/core";
import { ButtonSeo, Image, TextSeo, ViewSeo, useHover } from "@ctv/momentum";
import LanguageMenuSeo from "@ctv/shared/components/LanguageMenu/LanguageMenuSeo";
import { useHeaderHeight } from "@ctv/shared/components/Layout/HeaderHeightContext";
import TrackingButtonSeo from "@ctv/shared/components/Tracking/TrackingButtonSeo";
import {
  HeaderTrackingPage,
  trackArticleButtonClick,
  trackFeatureButtonClick,
  trackLoginButtonClick,
  trackProductButtonClick,
  trackSignUpButtonClick,
  trackTvlkImageClick,
} from "@ctv/shared/tracking/landing-page/buttonClick";

import styles from "./header.module.css";

type Props = {
  track?: HeaderTrackingPage;
};

export default function Header(props: Props) {
  const { track } = props;

  const [featureHover, featureHandlers] = useHover();
  const [productHover, productHandlers] = useHover();
  const [articleHover, articleHandlers] = useHover();
  const [_, setHeight] = useHeaderHeight();
  const auth = useAuth();

  const cr = useContentResource().CorporateRevampHomePage;

  return (
    <ViewSeo
      ref={(div) => {
        setHeight(div?.getBoundingClientRect().height);
      }}
      className={[styles.flexBox, styles.header].join(" ")}
    >
      <ViewSeo className={styles.flexBox}>
        <LocalizedLink href={"/"} track={trackTvlkImageClick(track)}>
          <Image
            src="/images/traveloka-for-corporates.png"
            alt="Traveloka for Corporates"
            width={282}
          />
        </LocalizedLink>

        <LocalizedLink
          {...featureHandlers}
          href={"/features"}
          track={trackFeatureButtonClick(track)}
        >
          <TouchableOpacity>
            <TextSeo
              ink={featureHover ? "black-secondary" : "black-primary"}
              variant="ui-baseline"
              className={styles.nav}
            >
              {cr.featuresText}
            </TextSeo>
          </TouchableOpacity>
        </LocalizedLink>
        <LocalizedLink
          {...productHandlers}
          href={"/products"}
          track={trackProductButtonClick(track)}
        >
          <TouchableOpacity>
            <TextSeo
              ink={productHover ? "black-secondary" : "black-primary"}
              variant="ui-baseline"
              className={styles.nav}
            >
              {cr.productsText}
            </TextSeo>
          </TouchableOpacity>
        </LocalizedLink>
        <LocalizedLink
          {...articleHandlers}
          href={"/articles/?page=1"}
          track={trackArticleButtonClick(track)}
        >
          <TouchableOpacity>
            <TextSeo
              ink={articleHover ? "black-secondary" : "black-primary"}
              variant="ui-baseline"
              className={styles.nav}
            >
              {cr.articlesText}
            </TextSeo>
          </TouchableOpacity>
        </LocalizedLink>
      </ViewSeo>
      <ViewSeo className={styles.flexBox}>
        <LanguageMenuSeo />
        <TrackingButtonSeo
          variant="secondary"
          text={cr.loginButtonText}
          className={styles.loginButton}
          onPress={auth.login}
          track={trackLoginButtonClick(track)}
        />
        <LocalizedLink href="/register" track={trackSignUpButtonClick(track)}>
          <ButtonSeo variant="main-cta" text={cr.signUpButtonText} />
        </LocalizedLink>
      </ViewSeo>
    </ViewSeo>
  );
}
