import Link from "next/link";

import { LocalizedLink, useContentResource } from "@ctv/core";
import { ButtonSeo, Image, TextSeo, Token, ViewSeo } from "@ctv/momentum";
import DividerSeo from "@ctv/momentum/Divider/DividerSeo";

import MailIcon from "@traveloka/icon-kit-web/react/IcContactMail";
import PhoneIcon from "@traveloka/icon-kit-web/react/IcContactPhone";
import ArrowRight from "@traveloka/icon-kit-web/react/IcSystemArrowRight12";
import HelpIcon from "@traveloka/icon-kit-web/react/IcSystemHelp";

import { CSF_URL } from "../constants";

import styles from "./footer.module.css";

export default function Footer() {
  const cr = useContentResource().CorporateRevampFooter;
  return (
    <ViewSeo className={styles.footer}>
      <ViewSeo spacing="xxxl" justify="evenly" className={styles.container} row>
        <Image
          alt="Traveloka for corporates logo"
          src="/images/traveloka-for-corporates-white.png"
          height={40}
        />
        <ViewSeo className={styles.flexOne} align="start" spacing="xs">
          <TextSeo variant="title-2" ink="white-primary">
            {cr.ourOfficeText}
          </TextSeo>
          <TextSeo variant="ui-small" ink="white-secondary">
            {cr.addressText}
          </TextSeo>
          <Link
            className={styles.link}
            href={"https://goo.gl/maps/SFSiT7TbhZUCaTzV9"}
            target="_blank"
          >
            <ButtonSeo
              className={styles.directionButton}
              size="small"
              variant="text-white"
              text={cr.getDirectionText}
              endIcon={<ArrowRight color={Token.color.lightPrimary} />}
            />
          </Link>
        </ViewSeo>
        <ViewSeo className={styles.flexOne} spacing="xs">
          <TextSeo variant="title-2" ink="white-primary">
            {cr.ourServiceText}
          </TextSeo>
          <Link className={styles.link} href={CSF_URL} target="_blank">
            <TextSeo variant="ui-small" ink="white-secondary">
              {cr.financeBusinessService}
            </TextSeo>
          </Link>
        </ViewSeo>
        <ViewSeo className={styles.flexOne} spacing="xs">
          <TextSeo variant="title-2" ink="white-primary">
            {cr.othersText}
          </TextSeo>
          <LocalizedLink
            className={styles.link}
            href="/terms-and-conditions"
            target="_blank"
          >
            <TextSeo variant="ui-small" ink="white-secondary">
              {cr.termsAndConditionsText}
            </TextSeo>
          </LocalizedLink>
        </ViewSeo>
        <ViewSeo className={styles.flexOne} spacing="xs">
          <TextSeo variant="title-2" ink="white-primary">
            {cr.needHelpText}
          </TextSeo>
          <ViewSeo spacing="xs" align="center" row>
            <PhoneIcon color={Token.color.lightPrimary} />
            <TextSeo variant="ui-small" ink="white-secondary">
              {cr.companyPhoneNumber}
            </TextSeo>
          </ViewSeo>
          <ViewSeo spacing="xs" align="center" row>
            <MailIcon color={Token.color.lightPrimary} />
            <TextSeo variant="ui-small" ink="white-secondary">
              {cr.companyEmail}
            </TextSeo>
          </ViewSeo>
        </ViewSeo>
      </ViewSeo>
      <DividerSeo subtle className={styles.divider} />
      <TextSeo
        variant="ui-small"
        ink="white-primary"
        className={styles.copyrightText}
      >
        {cr.copyrightText}
      </TextSeo>
    </ViewSeo>
  );
}
